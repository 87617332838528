import { useEffect, useState } from 'react'
import { t } from 'react-i18nify'
import AsyncStorage from '@react-native-async-storage/async-storage'

export function Settings() {
    const [autoSave, setAutoSave] = useState(null)
    useEffect(() => {
        ;(async () => {
            if (autoSave === null) {
                setAutoSave((await AsyncStorage.getItem('autoSave')) === 'true')
            } else {
                await AsyncStorage.setItem('autoSave', autoSave)
            }
        })()
    }, [autoSave])
    return (
        <div>
            <h2>Settings</h2>
            <form className="form">
                <label htmlFor="autosave" className="label-aside">
                    <input
                        type="checkbox"
                        id="autosave"
                        checked={!!autoSave}
                        onChange={(ev) => setAutoSave(!autoSave)}
                    ></input>
                    <div>
                        {t('Autosave')} ({t('experimental')})
                    </div>
                </label>
            </form>
        </div>
    )
}
