import { isMobile } from 'detect-touch-device'
import { t } from 'react-i18nify'

export function MobileFriendlyDateField({
    id,
    labelText,
    disabled = false,
    value,
    setValue,
} = {}) {
    function handleSetDateClick(ev) {
        ev.preventDefault()
        let now = [
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getUTCDay(),
        ]
            .map((v) => (v < 10 ? `0${v}` : v))
            .join('-')
        setValue(now)
        // setTimeout(() => {
        //     document.getElementById(id)?.showPicker()
        //     document.getElementById(id)?.click()
        // }, 200)
    }
    return (
        <label htmlFor={id} className="position-relative">
            <div>{labelText}:</div>
            {isMobile ? (
                <>
                    {value ? (
                        <>
                            <input
                                type="date"
                                disabled={disabled}
                                id={id}
                                value={value}
                                onChange={(ev) => setValue(ev.target.value)}
                                pattern="\d{4}-\d{2}-\d{2}"
                            ></input>
                            <div
                                className="close-button"
                                onClick={() => {
                                    setValue('')
                                }}
                            ></div>
                        </>
                    ) : (
                        <button onClick={handleSetDateClick}>
                            {t('Set date')}
                        </button>
                    )}
                </>
            ) : (
                <>
                    <input
                        type="date"
                        disabled={disabled}
                        id={id}
                        value={value}
                        onChange={(ev) => setValue(ev.target.value)}
                        pattern="\d{4}-\d{2}-\d{2}"
                    ></input>

                    <div
                        className="close-button"
                        onClick={() => {
                            setValue('')
                        }}
                    ></div>
                </>
            )}
        </label>
    )
}
