import AsyncStorage from '@react-native-async-storage/async-storage'
import * as api from './api'

async function removeAuthToken() {
    await AsyncStorage.removeItem('authToken')
    return true
}

// async function loadApiSessionAndUser(api) {
//     let authToken = await AsyncStorage.getItem('authToken')
//     if (!authToken) {
//         return null
//     }
//     api.setAuthToken(authToken)
//     let userRes = await api.user()
//     return {
//         user: userRes.data.data,
//         userSettings: JSON.parse(userRes.data.data.settings),
//     }
// }

async function setAuthToken(token) {
    await AsyncStorage.setItem('authToken', token)
}

async function getAuthToken() {
    let token = await AsyncStorage.getItem('authToken')
    return token
}

async function authorizedApi() {
    let authToken = await getAuthToken()
    if (!authorizedApi) {
        throw Error('No authToken found')
    }
    api.setAuthToken(authToken)
    return api
}

async function logoutAndClearSession() {
    try {
        await api.logout()
    } catch (e) {
        console.error(e)
    }
    await AsyncStorage.clear()
}

export {
    removeAuthToken,
    loadApiSessionAndUser,
    setAuthToken,
    getAuthToken,
    logoutAndClearSession,
    authorizedApi,
}
