// import { addHours, formatRelative, format } from 'date-fns'
// import * as DateFnsLocales from 'date-fns/locale'
import { t } from 'react-i18nify'

export { t }

export function permissionOptions() {
    return {
        '': t('Only for me'),
        visible: t('Visible for everyone who knows the QR-Code / URL'),
        editable: t('Editable for everyone who knows the QR-Code / URL'),
        editable_with_password: t('Editable with password'),
    }
}

export function permissionShort(permissionValue) {
    let map = {
        // '': 'Only for me',
        visible: t('Visible for everyone'),
        editable: t('Editable for everyone'),
        editable_with_password: t('Password protected'),
    }
    return map[permissionValue] ? map[permissionValue] : ''
}
