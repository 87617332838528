import './login.css'
import logo from '../../images/logo_alpha.svg'
import { Link } from 'react-router-dom'
import * as api from '../lib/api'
import { t } from '../lib/helper'
import { useState } from 'react'
import { setAuthToken, logoutAndClearSession } from '../lib/session_and_user'
import { showLoginState } from '../states/showLoginState'
import { useRecoilState } from 'recoil'
import { currentUserState } from '../states/currentUserState'

export function Login() {
    const [errorMessage, setErrorMessage] = useState('')
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [, setUser] = useRecoilState(currentUserState)

    // this is async, but we can ignore that in this case
    //logoutAndClearSession()

    const [showLogin, setShowLogin] = useRecoilState(showLoginState)

    async function handleSubmit(event) {
        event.preventDefault()
        let username = event.target.querySelector('input[name="email"]').value
        let password = event.target.querySelector(
            'input[name="password"]'
        ).value
        try {
            let token = await api.receiveAuthToken(username, password)
            if (token) {
                await setAuthToken(token)
                api.setAuthToken(token)
                const user = await api.user()
                setUser(user.email)
                // triggers a state change on parent app
                setShowLogin(false)
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 422) {
                    // wrong credentials? display forgot password
                    setShowForgotPassword(true)
                }
                setErrorMessage(e.response.data.message)
            } else {
                setErrorMessage(e.message)
            }
        }
    }

    function resetErrorMessage() {
        setErrorMessage('')
    }

    async function handleForgotPasswordClick(e) {
        let email = document.querySelector('input[type="email"]')?.value
        if (confirm(`Send password recovery mail to ${email}?`)) {
            await api.sendPasswordReset(email)
            alert('Done. Please check your mails')
        }
    }

    return (
        <div className="login full-height-and-centered no-space-between">
            <header>
                <div
                    className="close-button"
                    onClick={() => setShowLogin(false)}
                ></div>
                <h1>{t('Login')}</h1>
                <Link to="/signup">
                    <img src={logo} alt="logo" />
                </Link>
            </header>
            <form
                className="form"
                onSubmit={handleSubmit}
                onChange={resetErrorMessage}
            >
                {new URL(document.location).searchParams.get('from') ===
                    'initial_signup' && (
                    <div className="form-message">
                        You can login here after<br></br>you've confirmed your
                        email
                    </div>
                )}
                {errorMessage && (
                    <div className="form-message error">{errorMessage}</div>
                )}
                <label htmlFor="email">
                    <div>{t('E-Mail')}</div>
                    <input type="email" name="email" required></input>
                </label>
                <label htmlFor="password">
                    <div>{t('Password')}</div>
                    <input type="password" name="password" required></input>
                </label>
                <button role="submit" className="button">
                    Login
                </button>
            </form>
            {showForgotPassword && (
                <div
                    className="create-new-account-hint"
                    onClick={handleForgotPasswordClick}
                >
                    <a href="#">{t('Forgot Password?')}</a>
                </div>
            )}
            <div className="create-new-account-hint">
                <Link to="/signup" onClick={(ev) => setShowLogin(false)}>
                    {t('Signup here if no account')}
                </Link>
            </div>
        </div>
    )
}
