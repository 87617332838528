import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { logoutAndClearSession } from '../lib/session_and_user'
import { currentUserState } from '../states/currentUserState'
import AsyncStorage from '@react-native-async-storage/async-storage'

export function Logout() {
    const [user, setUser] = useRecoilState(currentUserState)
    useEffect(() => {
        ;(async () => {
            await logoutAndClearSession()
            setUser(null)
            await AsyncStorage.clear()
            window.location.href = '/'
        })()
    })

    return <div></div>
}
