import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

if (
    window.location.host.startsWith('app.codelabel.app ') ||
    window.location.host.startsWith('www.codelabel.app ')
) {
    Sentry.init({
        dsn: 'https://2783da1ef7cd46cbb1cf6f82139c42f5@o1128238.ingest.sentry.io/4503934715822080',
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

import React from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { App } from './app'

import './lib/i18n'

const root = createRoot(document.getElementById('app'))
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </React.StrictMode>
)
