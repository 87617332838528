import { useTranslation } from 'react-i18next'

import { useEffect, useState } from 'react'
import {
    BrowserRouter,
    Link,
    Route,
    Routes,
    Navigate,
    useNavigate,
} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { getAuthToken } from './lib/session_and_user'
import * as api from './lib/api'
import { Logout } from './components/Logout'
import { Login } from './components/Login'
import { Signup } from './components/Signup'
import { Home } from './components/Home'
import { TopNavigation } from './components/TopNavigation'
import { useRecoilState, useRecoilValue } from 'recoil'
import { showLoginState } from './states/showLoginState'
import { currentUserState } from './states/currentUserState'
import { toastMessageState } from './states/toastMessageState'
import { getAuthToken } from './lib/session_and_user'

import { t } from 'react-i18nify'
import { CreateLabels } from './components/CreateLabels'
import { Labels } from './components/Labels'
import { Label } from './components/Label'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './lib/i18n'
import { Settings } from './components/Settings'

function App() {
    const [user, setUser] = useRecoilState(currentUserState)
    const [showLogin, setShowLogin] = useRecoilState(showLoginState)
    const [authToken, setAuthToken] = useState(null)
    const [toastMessage] = useRecoilState(toastMessageState)

    useEffect(() => {
        if (!user) {
            ;(async () => {
                let token = await getAuthToken()
                if (token) {
                    api.setAuthToken(token)
                    setAuthToken(token)
                    let user = await api.user()
                    setUser(user.email)
                    console.info(`Logged in as ${user.email}`)
                    return
                }
                console.info('User not logged in')
            })()
        }
    }, [user])

    useEffect(() => {
        if (toastMessage?.message) {
            let { message } = toastMessage
            delete toastMessage.message
            toast(message, toastMessage)
        }
    }, [toastMessage])

    return (
        <div id="app-container" authtoken={authToken}>
            <BrowserRouter>
                {showLogin && (
                    <div id="login-and-signup-modal">
                        <Login />
                    </div>
                )}
                <main>
                    <TopNavigation></TopNavigation>

                    <div id="content">
                        <Routes>
                            {user ? (
                                <>
                                    <Route
                                        path="/"
                                        element={<Navigate to="/labels" />}
                                    />
                                    <Route
                                        path="/labels"
                                        element={<Labels></Labels>}
                                    />

                                    <Route
                                        path="/labels/new"
                                        element={<CreateLabels></CreateLabels>}
                                    />
                                    <Route
                                        path="/about"
                                        element={<Home></Home>}
                                    />
                                    <Route
                                        path="/settings"
                                        element={<Settings></Settings>}
                                    />
                                </>
                            ) : (
                                <>
                                    <Route path="/" element={<Home></Home>} />
                                    <Route
                                        path="/about"
                                        element={<Home></Home>}
                                    />

                                    <Route path="signup" element={<Signup />} />
                                    <Route
                                        path="login"
                                        element={
                                            <Navigate to="/" replace={true} />
                                        }
                                    />
                                </>
                            )}
                            <Route path="logout" element={<Logout></Logout>} />
                            <Route
                                path="/labels/:urlSegment"
                                element={<Label></Label>}
                            />
                        </Routes>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        // newestOnTop={false}
                        closeOnClick
                        // rtl={false}
                        // pauseOnFocusLoss
                        draggable
                        theme="dark"
                        pauseOnHover
                    />
                </main>
            </BrowserRouter>
        </div>
    )
}

export { App }
