import { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import * as api from '../lib/api'
import { permissionOptions } from '../lib/helper'
import { currentUserState } from '../states/currentUserState'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useDebounce } from 'use-debounce'
import './label.css'

import { t } from 'react-i18nify'
import { toastMessageState } from '../states/toastMessageState'
import { MobileFriendlyDateField } from './MobileFriendlyDateField'

export function Label() {
    const { urlSegment } = useParams()
    const [label, setLabel] = useState(null)
    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)
    const [followUpDate, setFollowUpDate] = useState(null)
    const [permission, setPermission] = useState(null)
    const [group, setGroup] = useState(null)
    const [hasPermission, setHasPermission] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [showPasswordPrompt, setShowPasswordPrompt] = useState(null)
    const [passwordPromptMessage, setPasswordPromptMessage] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(null)
    const [password, setPassword] = useState(null)
    const [otpPassword, setOtpPassword] = useState(null)
    const [toastMessage, setToastMessage] = useRecoilState(toastMessageState)
    // use, if record versioning is build in
    const [autoSave, setAutoSave] = useState(null)
    const [updateLabel, setUpdateLabel] = useState(null)
    const [formHasChanged, setFormHasChanged] = useState(false)

    const [debouncedLabel] = useDebounce(updateLabel, 500)

    const user = useRecoilValue(currentUserState)

    async function updateLabelRecord(updateLabel) {
        let data = { ...updateLabel }
        let { slug } = data
        if (!slug) {
            return
        }

        console.info('saved')
        delete data.slug
        try {
            await api.updateLabel(slug, data)
        } catch (e) {
            if (
                e.response?.status >= 400 &&
                e.response?.status !== 401 &&
                e.response?.status !== 404
            ) {
                let message =
                    e.response?.data?.message ||
                    e.response?.data?.error ||
                    e.message
                setToastMessage({ message })
            } else {
                console.error(e)
            }
        }
    }

    async function handleSubmit(ev) {
        ev.preventDefault()
        let updateData = {
            slug: urlSegment,
            follow_up: followUpDate,
            description,
            permission,
            title,
            group_name: group,
            password,
        }
        setUpdateLabel(updateData)
        await updateLabelRecord(updateData)
        setToastMessage({
            message: 'Saved',
            hideProgressBar: true,
            autoClose: 500,
        })
    }

    useEffect(() => {
        if (autoSave !== null) {
            return
        }
        ;(async () => {
            setAutoSave(
                (await AsyncStorage.getItem('autoSave', autoSave)) === 'true'
            )
        })()
    }, [autoSave])

    useEffect(() => {
        if (!autoSave) {
            return
        }
        if (!formHasChanged) {
            return
        }
        updateLabelRecord(updateLabel)
    }, [autoSave, debouncedLabel, formHasChanged])

    useEffect(() => {
        if (!autoSave || isLoading) {
            return
        }
        setUpdateLabel({
            slug: urlSegment,
            follow_up: followUpDate,
            description,
            permission,
            title,
            group_name: group,
            password,
        })
    }, [
        autoSave,
        followUpDate,
        description,
        permission,
        title,
        group,
        password,
        urlSegment,
        isLoading,
    ])

    function assignLabelData(labelData) {
        setLabel(labelData)
        setTitle(labelData.title)
        setDescription(labelData.description)
        setPermission(labelData.permission)
        setFollowUpDate(labelData.follow_up_date)
        setGroup(labelData.group_name)
        setImageUrl(labelData.images[0]?.url)
    }

    async function handlePasswordSubmit(ev) {
        ev.preventDefault()

        try {
            let otp = await api.createOTPPasswordForLabel(
                urlSegment,
                otpPassword
            )
            await api.publicLabel(urlSegment, otp)
            await AsyncStorage.setItem(`label/otp/${urlSegment}`, String(otp))
            let label = await api.publicLabel(urlSegment, otp)
            assignLabelData(label)
            setShowPasswordPrompt(false)
            setHasPermission(true)
        } catch (e) {
            if (e.response?.status === 401) {
                setOtpPassword('')
                return setPasswordPromptMessage(
                    t('Wrong password - Please try again')
                )
            }
            throw e
        }
    }

    async function handleImageUpload(ev) {
        let files = ev.target.files //imageUploadButtonRef.current?.files
        setIsUploadingImage(true)
        try {
            await api.uploadImageForLabel(urlSegment, files[0])
            let labelData = await api.label(urlSegment)
            setImageUrl(labelData.images[0]?.url)
            setIsUploadingImage(false)
        } catch (e) {
            console.error(e)
            let message = e.request?.statusText || e.message
            if (e.request?.response) {
                try {
                    let data = JSON.parse(e.request.response)
                    if (data.message) {
                        message = data.message
                    }
                } catch (_) {}
            }
            setToastMessage({
                message,
            })
        }
        setIsUploadingImage(false)
    }

    async function loadLabel(urlSegment) {
        try {
            let permission = await api.labelPermission(urlSegment, 'view')
            api.labelPermission(urlSegment, 'edit').then((permission) => {
                setHasPermissionToEdit(permission.granted)
            })
            setIsLoading(false)

            if (permission.granted) {
                setHasPermission(true)
                setShowPasswordPrompt(false)
                setErrorMessage(null)

                assignLabelData(await api.label(urlSegment))
            }
        } catch (e) {
            if (!e.request) {
                throw e
            }

            let errorMessage = e.request.statusText || 'Error'
            try {
                errorMessage = JSON.parse(e.request.response).error
            } catch (_) {}
            //
            if (e.request.status === 401) {
                setShowPasswordPrompt(true)
            } else {
                setShowPasswordPrompt(false)
            }
            setErrorMessage(e.request.status + ': ' + errorMessage)
        }
        setIsLoading(false)
    }

    async function loadPublicAvailableLabel(urlSegment) {
        let permission = await api.publicLabelPermission(urlSegment, 'view')
        setIsLoading(false)
        if (permission.granted) {
            setHasPermission(true)
            setShowPasswordPrompt(false)
            setErrorMessage(null)
            api.publicLabelPermission(urlSegment, 'edit').then((permission) => {
                setHasPermissionToEdit(permission.granted)
            })
            let label = await api.publicLabel(urlSegment)
            assignLabelData(label)
        } else {
            try {
                let otp = await AsyncStorage.getItem(`label/otp/${urlSegment}`)
                if (otp) {
                    let label = await api.publicLabel(urlSegment, otp)
                    assignLabelData(label)
                    setShowPasswordPrompt(false)
                } else {
                    setHasPermission(true)
                    setShowPasswordPrompt(true)
                }
            } catch (e) {
                setToastMessage({ message: e.message })
                setHasPermission(false)
                if (permission.message.toLowerCase().includes('password')) {
                    setShowPasswordPrompt(true)
                } else {
                    setErrorMessage('404 not found')
                }
            }
        }
    }

    useEffect(() => {
        if (isLoading || permission === false) {
            return
        }

        setFormHasChanged(false)

        if (!user) {
            loadPublicAvailableLabel(urlSegment)
        } else {
            loadLabel(urlSegment)
        }
    }, [user, urlSegment])

    return (
        <div
            className={[
                'full-height-centered',
                hasPermissionToEdit ? 'editable' : 'viewable',
            ].join(' ')}
            key={'label' + urlSegment}
        >
            {isLoading ? (
                <div className="loading">{t('Loading')}…</div>
            ) : (
                <>
                    {showPasswordPrompt && (
                        <div className="password-prompt">
                            <div className="inner">
                                <p>
                                    The following label requires an extra
                                    password (not the password of your account)
                                </p>
                                {passwordPromptMessage && (
                                    <p>{passwordPromptMessage}</p>
                                )}
                                <form
                                    className="form"
                                    onSubmit={handlePasswordSubmit}
                                >
                                    <label htmlFor="otp-temp-password">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="otp-temp-password"
                                        value={otpPassword}
                                        autoComplete=""
                                        onChange={(ev) =>
                                            setOtpPassword(ev.target.value)
                                        }
                                    ></input>
                                    <button role="submit">
                                        {t('Unlock with password')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}
                    {!showPasswordPrompt && errorMessage && (
                        <div className="error-message full-height-centered">
                            {errorMessage}
                        </div>
                    )}

                    {!showPasswordPrompt && !errorMessage && label && (
                        <form
                            className="form label-form"
                            onSubmit={handleSubmit}
                            onChange={() => setFormHasChanged(true)}
                        >
                            <label htmlFor="title">
                                <div>{t('Title')}:</div>
                                <input
                                    type="text"
                                    id="title"
                                    maxLength={128}
                                    disabled={!hasPermissionToEdit}
                                    defaultValue={title}
                                    placeholder={t('eg Books from boxes')}
                                    onChange={(ev) => setTitle(ev.target.value)}
                                ></input>
                            </label>
                            <label htmlFor="group_name">
                                <div>{t('Group')}:</div>
                                <input
                                    type="text"
                                    id="group_name"
                                    maxLength={48}
                                    disabled={!hasPermissionToEdit}
                                    defaultValue={group}
                                    placeholder={t('eg Moving Boxes')}
                                    onChange={(ev) => setGroup(ev.target.value)}
                                ></input>
                            </label>
                            <label htmlFor="description">
                                <div>{t('Description')}:</div>
                                {hasPermissionToEdit ? (
                                    <textarea
                                        id="description"
                                        maxLength={1024}
                                        onChange={(ev) =>
                                            setDescription(ev.target.value)
                                        }
                                        defaultValue={description}
                                    ></textarea>
                                ) : (
                                    <pre className="like-textarea">
                                        {description}
                                    </pre>
                                )}
                            </label>
                            {!hasPermissionToEdit && (
                                <label htmlFor="follow_up">
                                    <div>{t('Follow up')}:</div>
                                    <input
                                        type="text"
                                        id="follow_up"
                                        defaultValue={
                                            followUpDate || '----.--.--'
                                        }
                                    ></input>
                                </label>
                            )}

                            {hasPermissionToEdit ? (
                                <>
                                    <MobileFriendlyDateField
                                        id="follow_up"
                                        labelText={t('Follow up')}
                                        value={followUpDate || ''}
                                        setValue={setFollowUpDate}
                                    ></MobileFriendlyDateField>
                                    {imageUrl && (
                                        <details className="box">
                                            <summary>{t('Image')}</summary>
                                            <div>
                                                <img
                                                    src={imageUrl}
                                                    className="image"
                                                    loading="lazy"
                                                ></img>
                                                {/* {Math.round(
                                                    (label.images[0]
                                                        .size_in_bytes /
                                                        1024 /
                                                        1024) *
                                                        100
                                                ) / 100} */}
                                                <div
                                                    className="button small"
                                                    onClick={async (ev) => {
                                                        await api.deleteLabelImages(
                                                            urlSegment
                                                        )
                                                        setImageUrl(null)
                                                    }}
                                                >
                                                    Delete Image
                                                </div>
                                            </div>
                                        </details>
                                    )}
                                </>
                            ) : (
                                <>
                                    <img
                                        src={imageUrl}
                                        className="image"
                                        loading="lazy"
                                    ></img>
                                </>
                            )}

                            {hasPermissionToEdit && (
                                <>
                                    <label
                                        htmlFor="image"
                                        className={
                                            isUploadingImage ? 'uploading' : ''
                                        }
                                    >
                                        {isUploadingImage && (
                                            <div className="uploading-image-text">
                                                {t('Uploading Image')}
                                                <span className="loading-indicator"></span>
                                            </div>
                                        )}
                                        <input
                                            type="file"
                                            id="image"
                                            accept="image/*"
                                            disabled={!hasPermissionToEdit}
                                            onChange={handleImageUpload}
                                        ></input>
                                    </label>

                                    {label.user_email === user && (
                                        <>
                                            <label htmlFor="permission">
                                                <div>{t('Permission')}:</div>
                                                <select
                                                    onChange={(ev) =>
                                                        setPermission(
                                                            ev.target.value
                                                        )
                                                    }
                                                    defaultValue={permission}
                                                >
                                                    {Object.entries(
                                                        permissionOptions()
                                                    ).map((v) => {
                                                        return (
                                                            <option
                                                                key={
                                                                    'permission' +
                                                                    v[0]
                                                                }
                                                                value={v[0]}
                                                            >
                                                                {t(v[1])}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </label>

                                            {permission ===
                                                'editable_with_password' && (
                                                <label htmlFor="password">
                                                    <div>{t('Password')}:</div>
                                                    <input
                                                        type="password"
                                                        maxLength={128}
                                                        // required={true}
                                                        onChange={(ev) =>
                                                            setPassword(
                                                                ev.target.value
                                                            )
                                                        }
                                                    ></input>
                                                </label>
                                            )}
                                        </>
                                    )}
                                    {!autoSave && (
                                        <div className="actions">
                                            <button role="submit">
                                                {t('Save')}
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </form>
                    )}
                </>
            )}
        </div>
    )
}
