import './createlabels.css'
import { useEffect, useState } from 'react'
import { createLabels } from '../lib/api'
import { t } from 'react-i18nify'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { toastMessageState } from '../states/toastMessageState'
import { permissionOptions } from '../lib/helper'

export function CreateLabels() {
    const [numberOfLabels, setNumberOfLabels] = useState(1)
    const [permission, setPermission] = useState(null)
    const [descriptions, setDescriptions] = useState({})
    const [followUps, setFollowUps] = useState({})
    const [titles, setTitles] = useState({})
    const [onlyCreateLabelsWithTitle, setOnlyCreateLabelsWithTitle] =
        useState(true)
    const [groupName, setGroupName] = useState(null)
    const [startCountingAt, setStartCountingAt] = useState(1)
    const [password, setPassword] = useState(null)
    const [labelsToCreate, setLabelsToCreate] = useState([])
    const [numberTitles, setNumberTitles] = useState(false)
    const navigate = useNavigate()
    const setToastMessage = useSetRecoilState(toastMessageState)

    useEffect(() => {
        let labels = []

        if (numberOfLabels === 1) {
            labels = [
                {
                    permission,
                    description: descriptions[0] || null,
                    follow_up: followUps[0] || null,
                    titles: title[0],
                    group_name: groupName,
                    password,
                },
            ]
        } else {
            for (let i = 0; i < numberOfLabels; i++) {
                labels.push({
                    group_name: groupName,
                    follow_up: followUps[i] || null,
                    title:
                        titles[i] ||
                        (numberTitles ? `#${i + startCountingAt}` : null),
                    description: descriptions[i] || null,
                    permission,
                    password,
                })
            }
            if (onlyCreateLabelsWithTitle && !numberTitles) {
                labels = labels.filter((l) => !!l.title)
            }
        }
        setLabelsToCreate(labels)
    }, [
        numberOfLabels,
        onlyCreateLabelsWithTitle,
        numberTitles,
        groupName,
        titles,
        followUps,
        descriptions,
        password,
        startCountingAt,
    ])

    async function handleSubmit(ev) {
        ev.preventDefault()
        console.log(labelsToCreate)
        if (labelsToCreate.length === 1) {
            let _labels = await createLabels(labelsToCreate[0])
            setToastMessage({ message: t('Label created') })
            navigate(`/labels/${_labels.data.data.url_segment}`)
            return
        } else if (labelsToCreate.length > 1) {
            let { count, error } = (await createLabels(...labelsToCreate)).data
            setToastMessage({
                message: error
                    ? error
                    : t(
                          `${count} Labels created`,
                          { n: count }
                          // `${labels.length} Labels created`
                      ),
            })
            navigate(`/labels/`)
        } else {
            setToastMessage({
                message: t(`No Label created`),
            })
        }
    }

    return (
        <div>
            <form className="form">
                <div>
                    <label
                        htmlFor="number_of_labels"
                        className="label-left-aside"
                    >
                        <div>{t('How many labels')}:</div>
                        <input
                            type="range"
                            min="1"
                            max="100"
                            id="number_of_labels"
                            defaultValue={numberOfLabels}
                            onChange={(ev) =>
                                setNumberOfLabels(Number(ev.target.value))
                            }
                        ></input>
                        <div>
                            {numberOfLabels}
                            {' ' + t('Labels')}
                        </div>
                    </label>
                </div>

                <label htmlFor="group_name">
                    <div>{t('Group')}:</div>
                    <input
                        type="text"
                        id="group_name"
                        maxLength={48}
                        placeholder={t('eg Moving Boxes')}
                        value={groupName}
                        onChange={(ev) => setGroupName(ev.target.value)}
                    ></input>
                </label>
                {numberOfLabels == 1 ? (
                    <>
                        <label htmlFor="title">
                            <div>{t('Title')}:</div>
                            <input
                                type="text"
                                id="title"
                                maxLength={128}
                                placeholder={t('eg Books from boxes')}
                                onChange={(ev) =>
                                    setTitles({
                                        ...titles,
                                        ...{ 0: ev.target.value },
                                    })
                                }
                            ></input>
                        </label>
                        <label htmlFor="description">
                            <div>{t('Description')}:</div>
                            <textarea
                                id="description"
                                maxLength={1024}
                                onChange={(ev) =>
                                    setDescriptions({
                                        ...descriptions,
                                        ...{ 0: ev.target.value },
                                    })
                                }
                            ></textarea>
                        </label>
                        <label htmlFor="follow_up">
                            <div>{t('Follow up')}:</div>
                            <input
                                type="date"
                                onChange={(ev) =>
                                    setFollowUps({
                                        ...followUps,
                                        ...{ 0: ev.target.value },
                                    })
                                }
                            ></input>
                        </label>
                    </>
                ) : (
                    <>
                        <label htmlFor="number-titles" className="label-aside">
                            <input
                                type="checkbox"
                                id="number-titles"
                                checked={numberTitles}
                                onChange={(ev) =>
                                    setNumberTitles(!numberTitles)
                                }
                            ></input>
                            <div>{t('Number as title')}</div>
                        </label>
                        {numberTitles && (
                            <label
                                htmlFor="number-titles-starts-at"
                                className="label-left-aside"
                            >
                                <div>{t('Starts counting at')}:</div>
                                <input
                                    type="number"
                                    id="number-titles-starts-at"
                                    value={startCountingAt}
                                    min="0"
                                    onChange={(ev) => {
                                        setStartCountingAt(
                                            Number(ev.target.value)
                                        )
                                    }}
                                ></input>
                            </label>
                        )}
                        <details>
                            <summary>{`Edit ${numberOfLabels} Labels`}</summary>
                            <div className="bulk-edit-labels">
                                {numberOfLabels &&
                                    [...Array(numberOfLabels).keys()].map(
                                        (i) => {
                                            return (
                                                <>
                                                    <b>
                                                        {t('Label')} #{i + 1}
                                                    </b>
                                                    <div
                                                        className="bulk-edit-label"
                                                        key={`create-label-${i}`}
                                                    >
                                                        <label htmlFor="title">
                                                            <div>
                                                                {t('Title')}:
                                                            </div>
                                                            <input
                                                                type="text"
                                                                id="title"
                                                                maxLength={128}
                                                                value={
                                                                    numberTitles
                                                                        ? `#${
                                                                              i +
                                                                              startCountingAt
                                                                          }`
                                                                        : null
                                                                }
                                                                placeholder="e.g. tomato soup"
                                                                onChange={(
                                                                    ev
                                                                ) => {
                                                                    let data =
                                                                        {}
                                                                    data[i] =
                                                                        ev.target.value
                                                                    setTitles({
                                                                        ...titles,
                                                                        ...data,
                                                                    })
                                                                }}
                                                            ></input>
                                                        </label>
                                                        <label htmlFor="description">
                                                            <div>
                                                                {t(
                                                                    'Description'
                                                                )}
                                                                :
                                                            </div>
                                                            <textarea
                                                                id="description"
                                                                maxLength={1024}
                                                                onChange={(
                                                                    ev
                                                                ) => {
                                                                    let data =
                                                                        {}
                                                                    data[i] =
                                                                        ev.target.value
                                                                    setDescriptions(
                                                                        {
                                                                            ...descriptions,
                                                                            ...data,
                                                                        }
                                                                    )
                                                                }}
                                                            ></textarea>
                                                        </label>
                                                        <label htmlFor="follow_up">
                                                            <div>
                                                                {t('Follow up')}
                                                                :
                                                            </div>
                                                            <input
                                                                type="date"
                                                                onChange={(
                                                                    ev
                                                                ) => {
                                                                    let data =
                                                                        {}
                                                                    data[i] =
                                                                        ev.target.value
                                                                    setFollowUps(
                                                                        {
                                                                            ...followUps,
                                                                            ...data,
                                                                        }
                                                                    )
                                                                }}
                                                            ></input>
                                                        </label>
                                                    </div>
                                                </>
                                            )
                                        }
                                    )}
                            </div>
                        </details>
                    </>
                )}
                <label htmlFor="permission">
                    <div>{t('Permission')}:</div>
                    <select onChange={(ev) => setPermission(ev.target.value)}>
                        {Object.entries(permissionOptions()).map((v) => {
                            return <option value={v[0]}>{t(v[1])}</option>
                        })}
                    </select>
                </label>

                {permission === 'editable_with_password' && (
                    <label htmlFor="password">
                        <div>{t('Password')}:</div>
                        <input
                            type="password"
                            maxLength={128}
                            onChange={(ev) => setPassword(ev.target.value)}
                        ></input>
                    </label>
                )}

                {numberOfLabels > 1 && (
                    <label
                        htmlFor="only_create_labels_with_title"
                        className="label-aside"
                        style={{ display: 'none' }}
                    >
                        <input
                            type="checkbox"
                            id="only_create_labels_with_title"
                            defaultChecked={onlyCreateLabelsWithTitle}
                            onChange={(ev) =>
                                setOnlyCreateLabelsWithTitle(ev.target.checked)
                            }
                        ></input>
                        <div>{t('Only create Labels when having a title')}</div>
                    </label>
                )}

                <div className="actions">
                    <button
                        role="submit"
                        onClick={handleSubmit}
                        disabled={!labelsToCreate.length}
                    >
                        {t(
                            `Create ${labelsToCreate.length} labels`,
                            { n: labelsToCreate.length }
                            // `Create ${numberOfLabels} Labels`
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}
