import QRCode from 'qrcode-svg'
import { useEffect, useState } from 'react'
import { t } from 'react-i18nify'
import { Link } from 'react-router-dom'
import * as api from '../lib/api'
import * as helper from '../lib/helper'
import './labels.css'

export function Labels() {
    const [labels, setLabels] = useState(null)
    const [selectedLabels, setSelectedLabels] = useState({})
    const [selectedLabelsCount, setSelectedLabelsCount] = useState(0)
    const [qrCodeLabels, setQrCodeLabels] = useState(null)
    const [showPermissionSelect, setShowPermissionSelect] = useState(null)

    function handleSelectLabel(label, checked) {
        let data = selectedLabels
        if (checked) {
            data[label.url_segment] = checked
        } else {
            delete data[label.url_segment]
        }
        setSelectedLabelsCount(Object.keys(data).length)
        setSelectedLabels(data)
    }

    async function handleDeleteLabelClick(ev) {
        ev.preventDefault()
        if (confirm('Sure?')) {
            await api.deleteLabels(Object.keys(selectedLabels))
            setLabels(null)
        }
    }

    function handlePrintLabels(ev) {
        ev.preventDefault()

        document.querySelector('body').classList.add('print-qr-codes')
        let urlSegments = Object.keys(selectedLabels)
        let filteredLabels = labels.filter((l) =>
            urlSegments.includes(l.url_segment)
        )

        if (filteredLabels.length > 0) {
            setQrCodeLabels(filteredLabels)
        } else {
            setQrCodeLabels(null)
        }
        setTimeout(() => window.print(), 100)
    }

    useEffect(() => {
        if (labels) {
            return
        }
        ;(async () => {
            setLabels(await api.labels())
            setSelectedLabels({})
            setSelectedLabelsCount(0)
        })()
    }, [labels])

    useEffect(() => {
        if (qrCodeLabels) {
        }
    }, [qrCodeLabels])

    return (
        <div>
            <table className="labels">
                <thead>
                    <tr>
                        <td className="select">
                            <div className="form">
                                <input
                                    type="checkbox"
                                    onClick={(ev) => {
                                        ev.target.checked = !!ev.target.checked
                                        if (
                                            !ev.target.checked &&
                                            selectedLabelsCount === 0
                                        ) {
                                            return
                                        }
                                        document
                                            .querySelectorAll(
                                                'input.label-select-input'
                                            )
                                            .forEach((el) => el.click())
                                    }}
                                ></input>
                            </div>
                        </td>
                        <td>{t('Title')}</td>
                        <td>{t('Group')}</td>
                        <td className="permission">{t('Permission')}</td>
                        <td className="follow-up">{t('Follow up')}</td>
                    </tr>
                </thead>
                <tbody>
                    {labels?.map((label) => (
                        <tr key={label.url_segment}>
                            <td className="select">
                                <div className="form">
                                    <input
                                        type="checkbox"
                                        checked={selectedLabels?.url_segment}
                                        className="label-select-input"
                                        onChange={(ev) =>
                                            handleSelectLabel(
                                                label,
                                                ev.target.checked
                                            )
                                        }
                                    ></input>
                                </div>
                            </td>
                            <td>
                                <Link
                                    to={`/labels/${label.url_segment}`}
                                    className="not-as-link"
                                >
                                    {label.title}
                                </Link>
                            </td>
                            <td>
                                <Link
                                    to={`/labels/${label.url_segment}`}
                                    className="not-as-link"
                                >
                                    {label.group_name}
                                </Link>
                            </td>
                            <td className="permission">
                                <Link
                                    to={`/labels/${label.url_segment}`}
                                    className="not-as-link"
                                >
                                    {helper.permissionShort(label.permission)}
                                </Link>
                            </td>
                            <td className="follow-up">
                                <Link
                                    to={`/labels/${label.url_segment}`}
                                    className="not-as-link"
                                >
                                    {label.follow_up?.replace(/\s.*$/, '')}
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {labels && Object.keys(selectedLabels).length > 0 && (
                <div className="labels-actions">
                    <br></br>
                    <form className="form">
                        <button onClick={handleDeleteLabelClick}>
                            Delete selected labels
                        </button>
                        <button
                            onClick={(ev) => {
                                ev.preventDefault()
                                setShowPermissionSelect(!showPermissionSelect)
                            }}
                        >
                            Set Permissions for selected
                        </button>
                        <div>
                            {showPermissionSelect && (
                                <div>
                                    <br></br>
                                    <select
                                        onChange={async (ev) => {
                                            let value = ev.target.value
                                            if (value === '_') {
                                                return
                                            }
                                            for (let slug in selectedLabels) {
                                                await api.updateLabel(slug, {
                                                    permission: value,
                                                })
                                            }
                                            // reload lobels
                                            setLabels(null)
                                        }}
                                    >
                                        {Object.entries({
                                            ...{ _: 'No Changes' },
                                            ...helper.permissionOptions(),
                                        }).map((v) => {
                                            return (
                                                <option
                                                    key={'permission' + v[0]}
                                                    value={v[0]}
                                                >
                                                    {t(v[1])}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <br></br>
                                    <br></br>
                                </div>
                            )}
                        </div>
                        <button onClick={handlePrintLabels}>
                            Print selected
                        </button>
                    </form>
                </div>
            )}
            {qrCodeLabels && (
                <div className="qr-code-labels">
                    {[...qrCodeLabels].reverse().map((label) => {
                        return (
                            <div className="qr-code-label">
                                <div className="title">
                                    {label.group_name}{' '}
                                    {label.group_name && label.title ? '/' : ''}{' '}
                                    {label.title}
                                </div>
                                <div
                                    className="qrcode"
                                    dangerouslySetInnerHTML={{
                                        __html: new QRCode({
                                            content: label.share_url,
                                            width: 256,
                                            height: 256,
                                            color: '#000000',
                                            background: 'rgba(255,255,255,0)',
                                            ecl: 'M',
                                        }).svg(),
                                    }}
                                ></div>
                                <div className="made-by-codelabelapp">
                                    made with ❤️ by codelabel.app<br></br>
                                    {/* {label.share_url} */}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
