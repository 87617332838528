import checkmark from '../../images/checkmark-color.svg'
import logo from '../../images/logo_alpha.svg'
import './home.css'
import { useSetRecoilState } from 'recoil'
import { showLoginState } from '../states/showLoginState'
import { useNavigate } from 'react-router-dom'

export function Home() {
    const setShowLogin = useSetRecoilState(showLoginState)
    const navigate = useNavigate()
    return (
        <div className="home-screen">
            <h2>
                Manage your inventory
                <br></br>with QR Code labels
            </h2>
            <img src={logo} className="logo"></img>
            <div className="features">
                <ul>
                    <li>
                        <img src={checkmark}></img>
                        <strong>
                            Edit and share inventory data with QR Codes
                        </strong>
                    </li>
                    <li>
                        <img src={checkmark}></img>
                        <strong>
                            Keep track of food, storage, archives and more
                        </strong>
                    </li>

                    <li>
                        <img src={checkmark}></img>
                        <strong>Free</strong>
                    </li>
                </ul>
                <p>
                    <a
                        href="/signup"
                        className="signup"
                        onClick={(ev) => {
                            ev.preventDefault()
                            setShowLogin(false)
                            navigate('/signup')
                        }}
                    >
                        Signup
                    </a>
                </p>
            </div>
        </div>
    )
}
