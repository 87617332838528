import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentUserState } from '../states/currentUserState'
import { showLoginState } from '../states/showLoginState'
import './topnavigation.css'
import qrSquare from '../../images/qr_square.svg'
import arrowDown from '../../images/arrow-down.svg'
import QrScanner from 'qr-scanner'
import { useEffect, useRef, useState } from 'react'

export function TopNavigation() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const user = useRecoilValue(currentUserState)
    const videoRef = useRef(null)
    const [showLogin, setShowLogin] = useRecoilState(showLoginState)
    const [qrCodeScanner, setQrCodeScanner] = useState(null)
    const [qrScanActive, setQrScanActive] = useState(false)

    useEffect(() => {
        if (!videoRef) {
            return
        }
        let scanner = new QrScanner(videoRef.current, (result) => {
            const url = result ? new URL(result) : null
            if (result && url && /codelabel\.app$/.test(url.host)) {
                setQrScanActive(false)
                scanner.stop()
                console.info(url.pathname)
                // setPreviousQrLink(url.pathname)
                // window.location.replace(result)
                // location.pathname = url.pathname
                navigate(url.pathname)
            }
        })
        setQrCodeScanner(scanner)

        return () => {
            qrCodeScanner?.destroy()
            setQrCodeScanner(null)
            setQrScanActive(false)
        }
    }, [videoRef])

    return (
        <nav id="main-navi">
            <div className="logo">
                <div
                    onClick={() => {
                        if (qrScanActive) {
                            setQrScanActive(false)
                            qrCodeScanner?.stop()
                        } else {
                            setQrScanActive(true)
                            qrCodeScanner?.start()
                        }
                    }}
                >
                    <img src={qrSquare}></img>
                    <video
                        ref={videoRef}
                        className={qrScanActive ? 'qr-scan-active' : ''}
                    ></video>
                </div>
            </div>
            {user ? (
                <>
                    <Link to="/labels/new">{t('Create')}</Link>
                    <Link to="/labels">{t('Labels')}</Link>
                    <div id="additional-top-menu">
                        <div>
                            <img
                                src={arrowDown}
                                onClick={() =>
                                    document
                                        .getElementById('additional-top-menu')
                                        .classList.toggle('open')
                                }
                            ></img>
                            <div
                                className="items"
                                onClick={() =>
                                    document
                                        .getElementById('additional-top-menu')
                                        .classList.remove('open')
                                }
                            >
                                <Link to="/settings">Settings</Link>
                                <a
                                    href="/logout"
                                    onClick={(ev) => {
                                        ev.preventDefault()
                                        navigate('/logout')
                                    }}
                                >
                                    {t('Logout')}
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Link to="/">{t('About')}</Link>
                    <a
                        href="#"
                        onClick={(ev) => {
                            ev.preventDefault()
                            setShowLogin(true)
                        }}
                    >
                        {t('Login')}
                    </a>
                    <a
                        className="signup"
                        href="/signup"
                        onClick={(ev) => {
                            ev.preventDefault()
                            setShowLogin(false)
                            navigate('/signup')
                        }}
                    >
                        {t('Signup')}
                    </a>
                </>
            )}
        </nav>
    )
}
