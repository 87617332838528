import axios from 'axios'

// axios.defaults.withCredentials = true;

axios.defaults.baseURL =
    window?.location?.host?.startsWith('localhost') ||
    process?.env?.NODE_ENV === 'development'
        ? 'http://localhost:8000'
        : 'https://api.codelabel.app'

// axios.defaults.baseURL = 'http://192.168.1.12:8888'

let auth_token = null

// axios.interceptors.request.use((request) => {
//     // console.log('Starting Request', JSON.stringify(request, null, 2))
//     console.log({ url: request.url, method: request.method })
//     return request
// })

export function applyDefaultHeaders(headers = {}) {
    for (let k in headers) {
        axios.defaults.headers.common[k] = headers[k]
    }
}

export function setAuthToken(authToken) {
    auth_token = authToken
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
}

export function getAuthToken() {
    return auth_token
}

export async function logout() {
    return await axios.post('/api/v1/logout')
}

export async function user() {
    return (await axios.get('/api/v1/user')).data?.data
}

export async function receiveAuthToken(email, password, device_name = null) {
    if (!device_name) {
        device_name = navigator.userAgent.replace(/\s.+$/, '')
    }
    await axios.get('/sanctum/csrf-cookie')
    // this request above did just set cookie in application cookie, otherwise we'll get a CSRF mismatch error
    let { data } = await axios.post('/sanctum/token', {
        email,
        password,
        device_name,
    })
    return data
}

export async function csrfCookie() {
    return await axios.get('/sanctum/csrf-cookie')
}

export async function signup({ email, password, phone } = {}) {
    return await axios.post('/api/v1/signup', {
        email,
        password,
        phone,
    })
}

export async function emailIsAvailableForSignup(email) {
    let res = await axios.get(`/api/v1/signup/email_is_available/${email}`)
    return res.data.email_is_available && res.data.email_is_valid
}

export async function sendPasswordReset(email) {
    return await axios.post('/api/v1/forgot-password', {
        email,
    })
}

export async function version() {
    return await axios.get('/api/v1/version')
}

export async function deleteAccount(password) {
    return await axios.delete('/api/v1/account', { data: { password } })
}

export async function labels(page = 1) {
    return (await axios.get(`/api/v1/labels?page=${Number(page)}`)).data.data
}

export async function label(slug) {
    return (await axios.get(`/api/v1/labels/${slug}`)).data.data
}

export async function updateLabel(slug, data) {
    return await axios.patch(`/api/v1/labels/${slug}`, data)
}

export async function labelPermission(slug, action) {
    return (await axios.get(`/api/v1/labels/${slug}/permission/${action}`)).data
}

/* Does NOT require a login */
export async function publicLabelPermission(slug, action) {
    return (
        await axios.get(`/api/v1/public/labels/${slug}/permission/${action}`)
    ).data
}

/* Does NOT require a login */
export async function publicLabel(slug, otp = null) {
    return (
        await axios.get(`/api/v1/public/labels/${slug}`, {
            headers: {
                'X-Password': otp,
            },
        })
    ).data.data
}

export async function deleteLabels(slugs) {
    return axios.delete(
        `/api/v1/labels/${
            slugs.constructor === Array ? slugs.join(',') : slugs
        }`
    )
}

export async function deleteLabelImages(slug) {
    return axios.delete(`/api/v1/label-images/${slug}`)
}

export async function uploadImageForLabel(slug, file) {
    let formData = new FormData()
    formData.append('file', file)
    await axios.post(`/api/v1/labels/${slug}/image`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded', //'multipart/form-data',
        },
    })
}

export async function createOTPPasswordForLabel(slug, password) {
    return (
        await axios.post(`/api/v1/public/labels/${slug}/otp`, {
            password,
        })
    ).data.otp
}

export async function createLabels({
    user_id,
    group_name,
    title,
    description,
    permission,
    follow_up,
} = {}) {
    let labels = [...arguments]
    if (labels.length > 1) {
        // console.log([...arguments])
        return await axios.post('/api/v1/labels/bulk', {
            labels: [...arguments],
        })
    } else {
        return await axios.post('/api/v1/labels', labels[0])
    }
}
